@import "../variables/variables";

@mixin response-font($font-size) {
  font-size: calc(1.8rem + 3.3vw);

  @media (min-width: $screen-md) {
    & {
      font-size: $font-size;
    }
  }
}
