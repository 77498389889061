@import "../../mixins/mixin-response-font";
@import "../../mixins/mixin-flex-variant";

.cf-hero {
  color: var(--hero-text-color);
  text-align: center;
  font-size: $hero-font-size;
  line-height: 1.2;
  @include flex-variant(flex, center, center);
  flex-direction: column;

  &__link {
    font-size: $font-size-sm;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__btn-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: $space-base 0;

    [class*='cf-btn'] {
      margin: $space-base-half;
    }
  }

  p {
    margin: $space-base 8vw;
  }

  &__info {
    max-width: 80%;
    padding: $space-base-triple $space-base;

    h1 {
      @include response-font($hero-h1-font-size);
      color: var(--form-title-color);
      text-transform: capitalize;
      margin: 0;
      padding: 0;
    }
  }

  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      font-size: $hero-font-size-mobile;

      &__info {
      max-width: 100%;
        //adding this for the extra space for arrows in carousel
       margin-top: -5;
      }

      &__gap{
        padding-bottom: 10%;
      }
    }
  }
}

/**
* Background images.
*/
.cf-bg {
  &--detail {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &--home {
    min-height: 100vh;
    background: url("../../../images/home_bg.jpg") center/cover fixed no-repeat;
  }

  //volunteer/positions page
  &--volunteer {
    background: url("../../../images/volunteer_bg.jpg") center/cover fixed no-repeat;
  }

  &--donate {
    background: url("../../../images/volunteer_bg.jpg") center/cover fixed no-repeat;
  }

  &--opt{
    background: url("../../../images/volunteer_bg.jpg") center/cover fixed no-repeat;
  }

  &--internships {
    background: url("https://res.cloudinary.com/comida-for-familias-inc/image/upload/e_art:hokusai/v1635043292/comidaforfamilias-site/internships/internship_hero_ylajbz.jpg") center/cover fixed no-repeat;
  }

  &--events {
    background: url("../../../images/events_bg.jpg") center/cover fixed no-repeat;
  }

  &--projects {
    background: url("../../../images/project_bg.jpg") center/cover fixed no-repeat;
  }

  &--landingpage {
    background: var(--color-primary-light);
  }

  &--quisines {
    min-height: 100vh;
    background: url("../../../images/quisines_bg1.jpg") center/cover fixed no-repeat;
    margin: 0 auto;

    @media (max-width: $screen-sxx){
      text-align: left;
      p{
        margin-left: 0;
        font-size: $font-size-md;
      }
    }
  }

  &--ceointern {
    min-height: 100vh;
    background: url("../../../images/ceo_woman_whiteboard_crop.jpg") center/cover fixed no-repeat;
    margin: 0 auto;
    @media (max-width: $screen-sxx){
      text-align: left;
      background: url("../../../images/ceo_woman_center_edit.jpg") center/cover fixed no-repeat;
      p{
        margin-left: 0;
        font-size: $font-size-md;
      }
    }
  }

  &--about{
    background: url("../../../images/about.jpg") center/cover fixed no-repeat;
  }

  &--media{
    background: url("../../../images/media_bg.jpg") center/cover fixed no-repeat;
  }

  &--relaxassistant{
    min-height: 100vh;
    background: url("../../../images/sleepassistantcover.jpg") center/cover fixed no-repeat;
    margin: 0 auto;

    @media (max-width: $screen-sxx){
      text-align: left;
      p{
        margin-left: 0;
        font-size: $font-size-md;
      }
    }
  }
}
