// just for staggard animation
.cf-grid__col {
  &:nth-child(1) {
    transition-delay: 100ms;
  }
  &:nth-child(2) {
    transition-delay: 300ms;
  }
  &:nth-child(3) {
    transition-delay: 500ms;
  }
  &:nth-child(4) {
    transition-delay: 100ms;
  }
  &:nth-child(5) {
    transition-delay: 300ms;
  }
  &:nth-child(6) {
    transition-delay: 500ms;
  }
  &:nth-child(7) {
    transition-delay: 100ms;
  }
}
// end staggard animation

.cf-grid__cards li {
  width: 328px;
  height: 328px;
  display: inline-block;
  margin: 20px;
}

.cf-box {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  // -webkit-transition: 0.3s ease-in-out, -webkit-transform 0.3 ease-in-out;
  // -moz-transition: 0.3s ease-in-out, -moz-transform 0.3 ease-in-out;
  // transition: 0.3s ease-in-out, transform 0.3 ease-in-out;

  :hover {
    transform: scale(1.05);
  }
}

.hidden {
  opacity: 0.5;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}
// for those who prefer no animation
@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

.cf-info {
  position: absolute;
  width: inherit;
  height: inherit;

  h3 {
    font-weight: 400;
    color: white;
    margin: 0 30px;
    padding: 50px 0 0 0;
    line-height: 1.4;
  }
  p {
    color: white;
    margin: 20px 15px 0 15px;
  }
}

.cf-img-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(../../images/apple_logo.png);
}

.cf-img-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(../../images/usertesting_logo.png);
}
.cf-img-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(../../images/gsuite_logo.png);
}

.cf-img-4 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(../../images/medium_logo.png);
}

.cf-img-5 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(../../images/about.jpg);
}
.cf-img-6 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(../../images/youtube_logo.png);
}

.cf-lifestyle-img-1{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://relaxassistant.com/images/sleepyvector.png");
}

.cf-lifestyle-img-2{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://relaxassistant.com/images/sleepyvector.png");
}

.cf-lifestyle-img-3{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://relaxassistant.com/images/sleepyvector.png");
}

.cf-lifestyle-img-4{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://relaxassistant.com/images/sleepyvector.png");
}

.cf-lifestyle-img-5{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://relaxassistant.com/images/sleepyvector.png");
}

.cf-lifestyle-img-6{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://relaxassistant.com/images/sleepyvector.png");
}
