@import "../mixins/mixin-flex-variant";
@import "../variables/variables";

.cf-loading {
  @include flex-variant(flex, center, center);
  padding: $space-base-double 0;

  [class*='css-']:not(.css-0) {
    background-color: var(--color-primary);
  }
}
