* {
    margin: 0;
    padding: 0;
}

.header {
    //background: rgba();
    height: 100vh;
}

.video-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
}

@media (max-aspect-ratio:16/10) {
    .video-background {
        width: auto;
        height: 100%;
    }
}

.welcome {
    position: relative;
    text-align: center;
    font-family: monospace;
    color: #fff;
    top: 150px;
}

.welcome h1 {
    font-size: 80px;
    font-weight: 100;
    letter-spacing: 5px;
    margin-bottom: 30px;
}

.btn {
    font-size: 18px;
    color: #fff;
    margin: 5px;
    padding: 7px 22px;
    text-decoration: none;
    border: 1px solid grey;
    border-radius: 25px;
}

.btn-howdy:hover {
    background: #f87b02;
    transition: 1s;
    border: hidden;
}

.btn-events:hover {
    background: #f87b02;
    transition: 1s;
    border: hidden;
}

.icon:hover{
    transform: scale(1.05, 1.05);
    transition: 1s;
    border: hidden;
}