
@import "../variables/variables.scss";
@import "../components/inputs/mixin-input-variant.scss";

.modal-backdrop {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(44, 44, 44, 0.8);
   
    overflow-y: auto;
}

.modal-wrapper{
    width: 800px;
    height: 500px;
    box-shadow: 0 5px 16px rgba($color: #000000, $alpha: 1.0);
    background: #fff;
    color: var(--input-border--action);
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 101;
    border-radius: 10px;
    
}

.modal-img{
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background: #000;
}

.modal-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #141414;
}

.modal-close-btn{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 102;
    &:hover{
        transform: scale(1.5);
    }
}