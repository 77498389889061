@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

.cf-donate {
  &__comment {
    display: flex;
    font-style: italic;
  }

  &__info {
    text-align: center;
    margin-bottom: $space-base;
  }
  &__title {
    padding: $space-base-double 0 $space-base;
  }

  &__list {
    margin: -$space-base-half;

    li {
      display: block;
      text-align: center;
      line-height: 1.2;
      padding: $space-base-half;
      font-size: $font-size-sm;
    }

    .cf-ico {
      color: var(--color-primary);
      font-weight: 600;
      font-size: $font-size-md;
      margin-right: $space-base-half;
    }

    // For Desktop & Tablet.
    @media (min-width: $screen-sxx) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      li {
        @include flex-variant(flex, center, center);
      }
    }
  }
}

.cf-center {
  text-align: center;
}
