@import "../../variables/variables";
@import "../../mixins/mixin-flex-variant";

.cf-card-list {
  > [class*='cf-card']:not(:last-child) {
    margin-bottom: $space-base;
  }

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    & {
      max-width: unset;
      > [class*='cf-card']:not(:last-child) {
        margin-bottom: $space-base-double;
      }
    }

  }

}
