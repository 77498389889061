@import "../../variables/variables";
@import "mixin-input-variant";

.cf-input {
  @include input-variant(
      $input-height,
      100%,
      var(--input-color),
      var(--input-border),
      var(--input-bg),
      var(--input-border--action),
  );
}
