@import "../mixins/mixin-flex-variant";
@import "../variables/variables";

.cf-grid {
  margin: -$grid-title;

  // For Desktop & Tablet.
  @media (min-width: $screen-sm) {
    @include flex-variant(flex, center, flex-start);
    flex-wrap: wrap;
  }

  &__ico {
    background: var(--grid-ico-bg);
    color: var(--grid-ico-color);
    @include flex-variant(flex, center, center);
    border-radius: 50%;
    width: $grid-ico-circle-size;
    height: $grid-ico-circle-size;
    font-size: $font-size-sm;
    font-weight: 600;
    margin-bottom: $space-base-half;

    svg {
      height: $grid-ico-size;
      width: $grid-ico-size;
    }
  }

  &__logo{
    height: 150px;
    //width: $grid-ico-size;
  }

  &__title {
    font-size: $grid-title;
    padding: $space-base-half 0 $space-base-half;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.1;
  }

  &__col {
    @include flex-variant(flex, center, center);
    text-align: center;
    flex-direction: column;
    padding: $grid-spaces;
    font-size: $grid-font-size; 

    // For Desktop & Tablet.
    @media (min-width: $screen-sm) {
      flex-basis: $grid-width;
    }
  }
}
