@import "../../variables/variables";
@import "../../mixins/mixin-flex-variant";
@import "mixin-card-style";

.cf-card-preview {
  @include card-style;
  position: relative;

  // For Desktop & Tablet.
  @media (min-width: $screen-sm) {
    display: flex;
  }

  &__info {
    flex: 1;
  }

  &__body {
    padding: $space-base;

    // For Desktop & Tablet.
    @media (min-width: $screen-sm) {
      flex: 1;
      padding: $space-base-double;
    }

    // For Desktop (Large Screen).
    @media (min-width: $screen-lg) {
      display: flex;
    }
  }

  &__actions {
    padding: $space-base 0 0;

    // For Desktop (Large Screen).
    @media (min-width: $screen-lg) {
      @include flex-variant(flex, center, center);
      flex-direction: column;
      padding: $space-base 0 0 $space-base-double;
    }
  }

  &__title {
    text-align: inherit;
    margin: 0;
    line-height: 1.2;
    padding: $card-spaces-title;
    font-size: $card-font-size-title-mob;
    font-weight: 600;
    color: var(--card-title-color);
    text-transform: capitalize;
    display: block;

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      font-size: $card-font-size-title;
    }
  }

  &__badge {
    position: absolute;
    top: $space-base-half;
    right: $space-base-half;
  }

  /*
  * If Card has Image Block.
  */
  &__img {
    min-width: 40%;
    min-height: 300px;
    display: flex;
    overflow: hidden;
    background-color: var(--color-grey-light);
  }

  /*
  * Animate Preview Image.
   */
  .cf-img-animate {
    flex: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: transform $transition-base;
  }

  &:hover {
    .cf-img-animate {
      transform: scale(1.05);
    }
  }

  /*
  * If Card has Image Block.
  */
  &--small {
    .cf-card-preview__title {
      font-size: $font-size-sm;
    }

    .cf-card-preview__img {
      min-width: $card-preview-img-size;
      min-height: $card-preview-img-size;

      // For Desktop & Tablet.
      @media (min-width: $screen-sm) {
        width: $card-preview-img-size;
        height: $card-preview-img-size;
      }
    }
  }
}
