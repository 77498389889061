@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

.cf-form {
  margin: -$form-row-spaces;

  &__row {
    padding: $form-row-spaces;
    position: relative;
  }

  &__hint {
    position: absolute;
    bottom: -10px;
    font-size: $font-size-small;
    color: var(--color-primary-dark);
  }

  // Exception - if textarea + hint.
  .cf-textarea + .cf-form__hint {
    bottom: 0;
  }

  &__actions {
    padding: $form-actions-spaces;
    margin: -$space-base-half;
    @include flex-variant(flex, center, center);
    flex-wrap: wrap;

    [class*='cf-btn'] {
      margin: $space-base-half;
    }

    // For Desktop & Tablet.
    @media (min-width: $screen-sxx) {
      justify-content: flex-end;
    }
  }
}
