li{
    margin: .75rem 0;
    font-size: medium;
}

.card-body{
    background-color: var(--color-white) ;
    color: var(--color-secondary);

}

.card-header{
    background-color: var(--color-primary-dark);
}

.text-muted{
    font-size: x-large;
}

.sm-space{
    margin: 5rem 0;
}

.text-center{
    text-align: center !important;
}