@import "mixin-btn-variant";

/*
  * Usage example:
  * <a class="cf-btn--primary"
  *    href="..."
  *    target="_blank"><span>...</span></a>
  * or
  * <button class="cf-btn--primary"><span>...</span></button>
 */

.cf-btn--primary {
  @include btn-variant(
      var(--btn-primary-color),
      var(--btn-primary-border),
      var(--btn-primary-bg),
      var(--btn-primary-color--action),
      var(--btn-primary-border--action),
      var(--btn-primary-bg--action),
  );
}
