/*
* var() - Usage example:
* https://developer.mozilla.org/en-US/docs/Web/CSS/var
*/

:root {
  /*
  * Basic theme colors.
  */
  --color-white: #FFFFFF;

  --color-grey-darker: #222;
  --color-grey-dark: #717171;
  --color-grey: #cfcfcf;
  --color-grey-light: #f5f5f5;

  --color-primary-light: #FC9F76;
  --color-primary: #EE7A45;
  --color-primary-dark: #e76730;

  --color-primary-rgba: rgba(231, 103, 48, .8);

  --color-secondary-light: #66A8CE;
  --color-secondary: #397598;
  --color-secondary-dark: #172139;

  /*
  * Hero.
  */
  --hero-text-color: var(--color-white);

  /*
  * Body.
  */
  --body-color: var(--color-grey-darker);

  /*
  * Shadow.
  */
  --shadow-light: 2px 5px 3px 0px rgba(0, 0, 0, 0.27);
  --shadow-dark: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);

  /*
  * Footer.
  */
  --footer-bg: var(--color-secondary-dark);
  --footer-color: var(--color-white);

  /*
  * Buttons.
  */
  // Primary.
  --btn-primary-color: var(--color-white);
  --btn-primary-border: var(--color-primary);
  --btn-primary-bg: var(--color-primary);

  --btn-primary-color--action: var(--color-white);
  --btn-primary-border--action: var(--color-primary-dark);
  --btn-primary-bg--action: var(--color-primary-dark);

  // Primary-O.
  --btn-primary-o-color: var(--color-primary);
  --btn-primary-o-border: var(--color-primary);
  --btn-primary-o-bg: transparent;

  --btn-primary-o-color--action: var(--color-white);
  --btn-primary-o-border--action: var(--color-primary-dark);
  --btn-primary-o-bg--action: var(--color-primary-dark);

  // Secondary.
  --btn-secondary-color: var(--color-white);
  --btn-secondary-border: var(--color-secondary-light);
  --btn-secondary-bg: var(--color-secondary-light);

  --btn-secondary-color--action: var(--color-white);
  --btn-secondary-border--action: var(--color-secondary-light);
  --btn-secondary-bg--action: var(--color-secondary-light);

  // Secondary-O.
  --btn-secondary-o-color: var(--color-secondary-light);
  --btn-secondary-o-border: var(--color-secondary-light);
  --btn-secondary-o-bg: transparent;

  --btn-secondary-o-color--action: var(--color-white);
  --btn-secondary-o-border--action: var(--color-secondary-light);
  --btn-secondary-o-bg--action: var(--color-secondary-light);

  // White-O.
  --btn-white-o-color: var(--color-white);
  --btn-white-o-border: var(--color-white);
  --btn-white-o-bg: transparent;

  --btn-white-o-color--action: var(--body-color);
  --btn-white-o-border--action: var(--color-white);
  --btn-white-o-bg--action: var(--color-white);

  /*
  * Navigation.
  */
  --nav-font-size-color: var(--color-primary);

  --nav-bg: var(--color-white);
  --nav-border: var(--color-primary);

  --nav-btn-bg: var(--color-primary);
  --nav-btn-bg--action: var(--color-primary-dark);
  --nav-btn-color--action: var(--color-white);
  --nav-btn-color: var(--color-white);

  --nav-menu-item-color--actions: var(--color-primary);
  --nav-dropdown-item-color--actions: var(--color-white);

  /*
  * Scrollbar.
  */
  --scrollbar-bg: var(--color-primary-dark);

  /*
  * Inputs.
  */
  --input-color: var(--body-color);
  --input-border: var(--color-grey);
  --input-bg: var(--color-white);
  --input-border--action: var(--color-primary);

  /*
  * Custom Focus.
  */
  --focus-box: var(--color-primary-light);

  /*
  * Sections.
  */
  --section-gray: var(--color-grey-light);

  /*
  * Cards.
  */
  --card-border: var(--color-grey-light);
  --card-title-color: var(--body-color);

  /*
  * Cards.
  */
  --grid-ico-bg: var(--color-primary);
  --grid-ico-color: var(--color-white);

  /*
  * List.
  */
  --list-circle-bg: var(--color-primary-dark);

  /*
  * Breadcrumbs.
  */
  --breadcrumbs-color: var(--color-primary-dark);
  --breadcrumbs-border: var(--color-primary-light);
  --breadcrumnbs-border--action: var(--color-primary-dark);

  /*
  * Share Ico.
  */
  --share-inline-ico-color: var(--color-primary);

  /*
  * Switcher.
  */
  --switcher-label-action-bg: var(--color-primary);
  --switcher-label-border-action-color: var(--color-primary);
  --switcher-labe-actionl-color: var(--color-primary);
  --switcher-label-border-color: var(--color-grey-light);
  --switcher-label-hover-color: var(--color-white);

  /*
  * Selection.
  */
  --selection-bg: var(--color-primary);
  --selection-color: var(--color-white);

  /*
  * Badge.
  */
  --badge-default-bg: var(--color-grey);
  --badge-default-color: var(--body-color);

  --badge-secondary-bg: var(--color-secondary-dark);
  --badge-secondary-color: var(--color-white);

  --badge-primary-bg: var(--color-primary-light);
  --badge-primary-color: var(--color-white);


  /*
  * Date.
  */
  --date-color: var(--color-grey-dark);
}
