@import "../../variables/variables";
@import "../../mixins/mixin-focus-variant";

@mixin input-variant(
  $height,
  $width,
  $color,
  $border,
  $background,
  $border-action,
  $textarea: 'input'
) {
  height: $height;
  width: 100%;
  color: $color;
  border: 1px solid $border;
  background-color: $background;
  border-radius: $border-radius-base;
  font-family: $body-font-family;

  &:active,
  &:hover {
    border-color: $border-action;
  }

  &:disabled {
    opacity: .6;
    cursor: default;
  }

  // For Desktop & Tablet.
  @media (min-width: $screen-sm) {
    width: $width;
  }

  // If invalid field.
  &.is-invalid {
    border-color: var(--color-primary-dark);
  }

  @if $textarea == 'textarea' {
    @include focus-variant;
    max-height: $textarea-height-max;
    min-height: $textarea-height;
    resize: vertical;
    padding: $textarea-spaces;

  } @else if $textarea == 'input' {

    input {
      width: 100%;
      height: 100%;
      padding: $input-spaces;
      border: none;
      background-color: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $input-font-size;
      color: currentColor;
      @include focus-variant;
    }
  }
}
