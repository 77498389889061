@import "../mixins/mixin-response-font";
@import "../variables/variables";

.cf-section-promo {
  color: var(--color-white);
  background-color: var(--color-secondary-dark);
  position: relative;
  display: flex;

  a {
    color: var(--color-white);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .cf-title {
    line-height: 1.2;
    padding: 0 0 $space-base;
    font-size: $title-font-size-mob;

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      font-size: $title-font-size;
    }
  }

  .cf-subtitle,
  .cf-title {
    font-weight: 600;
    text-transform: capitalize;
  }

  .cf-subtitle {
    text-transform: uppercase;
    font-size: $font-size-sm;
  }

  .cf-paragraph{
    font-size: $font-size-sm;
  }

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    &__img,
    &__body {
      flex: 1;
    }

    &__img {
      background: url(../../images/donate-bg.jpg) no-repeat right;
      background-size: cover;
      min-width: 20%;
    }

    &__positionimg {
      background: url(../../images/volunteerpicture.jpeg) no-repeat right;
      background-size: cover;
      min-width: 40%;
    }

    &__volunteerpic{
      background: url(../../images/volunteer_mentor.jpg) no-repeat right;
      background-size: cover;
      min-width: 40%;
    }

  }

  &__body {
    padding: $section-secondary-spaces;

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      padding: $section-secondary-spaces-big;
    }
  }

  &__info {

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      max-width: $section-secondary-info-width;
    }

    li:not(:last-child) {
      margin-bottom: $space-base;
    }
  }
}

.cf-section-promo-reverse {
  color: var(--color-white);
  background-color: var(--color-secondary-dark);
  position: relative;
  display: flex;
  flex-direction: row-reverse;

  a {
    color: var(--color-white);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .cf-title {
    line-height: 1.2;
    padding: 0 0 $space-base;
    font-size: $title-font-size-mob;


    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      font-size: $title-font-size;
    }
  }

  .cf-subtitle,
  .cf-title {
    font-weight: 600;
    text-transform: capitalize;
  }

  .cf-subtitle {
    text-transform: uppercase;
    font-size: $font-size-sm;
  }

  .cf-paragraph{
    font-size: $font-size-sm;
  }

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    &__img,
    &__body {
      flex: 1;
    }

    &__img {
      background: url(../../images/donate-bg.jpg) no-repeat left;
      background-size: cover;
      min-width: 20%;
    }

    &__positionimg {
      background: url(../../images/volunteerpicture.jpeg) no-repeat left;
      background-size: cover;
      min-width: 40%;
    }


    &__volunteerpic{
      background: url(../../images/volunteer_mentor.jpg) no-repeat right;
      background-size: cover;
      min-width: 40%;
    }
  }

  &__body {
    padding: $section-secondary-spaces;

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      padding: $section-secondary-spaces-big;
    }
  }

  &__info {

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      max-width: $section-secondary-info-width;
    }

    li:not(:last-child) {
      margin-bottom: $space-base;
    }
  }
}
