@import "../mixins/mixin-container";
@import "../mixins/mixin-flex-variant";
@import "../variables/variables";

.cf-footer {
  background-color: var(--footer-bg);
  color: var(--footer-color);

  a {
    color: var(--footer-color);
  }

  &__container {
    @include container($footer-spaces);
  }

  &__info {
    margin: -$footer-spaces-info-col;

    // For Desktop & Tablet.
    @media (min-width: $screen-sm) {
      @include flex-variant(flex, space-between, unset);
      flex-wrap: wrap;
    }

    .cf-col {
      padding: $footer-spaces-info-col;
    }
  }

  &__list {
    li {
      padding: $footer-spaces-list;
    }
  }

  &__socials {
    @include flex-variant(flex, flex-start, center);
    margin: -$footer-spaces-socials;

    a {
      padding: $footer-spaces-socials;
      text-decoration: none;
    }

    // For Desktop & Tablet.
    @media (min-width: $screen-sm) {
      justify-content: center;
    }
  }

  &__copyright {
    padding: $footer-spaces-copyright;

    // For Desktop & Tablet.
    @media (min-width: $screen-sm) {
      text-align: right;
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__donors {
    padding-top: $space-base-half;
  }
}

/*
* Helpers.
*/
.cf-uppercase {
  text-transform: uppercase;
}
