@import "../../variables/variables";
@import "mixin-input-variant";

.cf-textarea {
  @include input-variant(
      $textarea-height,
      100%,
      var(--input-color),
      var(--input-border),
      var(--input-bg),
      var(--input-border--action),
      'textarea'
  );
}
