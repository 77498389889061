@import "../mixins/mixin-flex-variant";
@import "../variables/variables";

.cf-contact {
  margin: -$contact-col-space;

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    @include flex-variant(flex, space-between, unset);
    flex-wrap: wrap;
  }

  &__col {
    flex: 1;
    padding: $contact-col-space;
  }

  [class*='cf-btn--'] {
    min-width: 160px;
  }
}
