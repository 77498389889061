@import "mixin-btn-variant";

/*
  * Usage example:
  * <a class="cf-btn--primary-o"
  *    href="..."
  *    target="_blank"><span>...</span></a>
  * or
  * <button class="cf-btn--primary-o"><span>...</span></button>
 */

.cf-btn--primary-o {
  @include btn-variant(
      var(--btn-primary-o-color),
      var(--btn-primary-o-border),
      var(--btn-primary-o-bg),
      var(--btn-primary-o-color--action),
      var(--btn-primary-o-border--action),
      var(--btn-primary-o-bg--action),

  );

}
