@import "../variables/variables";

.cf-sidebar {
  &__link {
    height: 40px;
    display: flex;
    align-items: center;
    padding: $space-base-small $space-base;

    &.is-active {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }

  &__btn {
    position: relative;
    border: 0;
    background-color: transparent;
    padding: $space-base;
    cursor: pointer;
    outline: none;

    &.is-open {
      color: var(--color-primary);
    }
  }

  &__nav {
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--color-white);
    top: $nav-height;
    min-width: 240px;
    z-index: $z-index-sidebar-nav;
    border-right: 1px solid var(--color-primary);
  }
}

.cf-menu-mobile {
  display: block;

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    display: none;
  }
}
