@import "../variables/variables";

/**
* <div className="cf-breadcrumbs">
*     <a className="cf-breadcrumbs__link" href="/"><span>...</span></a>
*     <span className="cf-breadcrumbs__current">...</span>
* </div>
*/

.cf-breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -$breadcrumbs-spaces-mob;
  margin-right: -$breadcrumbs-spaces-mob;
  padding-bottom: $space-base;

  &__link,
  &__current {
    padding: $breadcrumbs-spaces-mob;
    margin: 0;
    text-transform: uppercase;
    font-size: $breadcrumbs-font-size;
    display: block;
    position: relative;
    line-height: 1.2;
  }

  &__link {
    color: var(--breadcrumbs-color);

    span {
      border-bottom: 1px dashed var(--breadcrumbs-border);
    }

    &:hover {
      text-decoration: none;

      span {
        border-bottom-color: var(--breadcrumbs-border--action);
      }
    }
  }

  // For Desktop & Tablet.
  @media (min-width: $screen-sxx) {
    & {
      margin-left: -$breadcrumbs-spaces;
      margin-right: -$breadcrumbs-spaces;

      &__link,
      &__current {
        padding: $breadcrumbs-spaces;
      }

      >:not(:last-child) {
        &:after {
          content: '';
          display: block;
          height: $breadcrumbs-circle-size;
          width: $breadcrumbs-circle-size;
          border-radius: 50%;
          background-color: var(--breadcrumbs-border);

          position: absolute;
          right: -$breadcrumbs-circle-size/2;
          top: 50%;
          margin-top: -$breadcrumbs-circle-size/2;
        }
      }
    }
  }
}
