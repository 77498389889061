@import "../variables/variables";
@import "../mixins/mixin-response-font";

.cf-title-primary {
  padding: 0 0 $space-base 0;
  font-size: $font-size-md;
  font-weight: 600;
  line-height: 1.1;
  text-transform: capitalize;
  text-align: center;

  span {
    color: var(--color-primary);
  }

  /*
  * Sizes.
  */
  &.cf-size-big {
    @include response-font($hero-h1-font-size);
  }

  &.cf-size-section {
    display: block;
    text-transform: capitalize;
    margin: 0;
    padding: $title-spaces;
    color: currentColor;
    font-size: $title-font-size-mob;
    line-height: 1.2;

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      font-size: $title-font-size;
      padding: $title-spaces-big;
    }
  }

  /*
  * Align.
  */
  &.cf-align-left {
    text-align: left;
  }
}
