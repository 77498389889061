/*
* Font.
*/
$font-size-lgt: 60px;
$font-size-lg: 44px;
$font-size-md: 24px;
$font-size-sm: 16px;
$font-size-base: 14px;
$font-size-small: 13px;

/*
* Body.
*/
$body-font-family: Poppins, sans-serif;
$body-line-height: 2;
$body-font-size: $font-size-base;

/*
* Spaces.
*/
$space-base: 16px;
$space-base-double: $space-base*2;
$space-base-triple: $space-base*3;
$space-base-cube: $space-base*4;
$space-base-biggest: 100px;

$space-base-half: $space-base/2;
$space-base-small: $space-base/4;

/*
* Hero.
*/
$hero-h1-font-size: $font-size-lgt;
$hero-font-size: 20px;
$hero-font-size-mobile: $font-size-base;

/*
* Screens.
*/
$screen-sxx: 460px;
$screen-sx: 560px;
$screen-sm: 780px;
$screen-md: 968px;
$screen-lg: 1200px;

/*
* Navigation.
*/
$nav-height: 68px;
$nav-width-mobile: 240px;

$nav-logo-font-size: $font-size-sm;
$nav-logo-img-size: 50px;
$nav-logo-img-spaces: $space-base-half $space-base;

$nav-btn-spaces: 0 $space-base;
$nav-btn-width: 120px;

$nav-link-spaces-desktop: 0 $space-base;

$nav-dropdown-max-width: 300px;
$nav-dropdown-min-width: 140px;
$nav-dropdown-ico-size: 20px;
$nav-dropdown-link-height: 40px;
$nav-dropdown-link-spaces: 0 $space-base;

/*
* Z-index.
*/
$z-index-nav: 10;
$z-index-sidebar-nav: 10;
$z-index-sidebar-overlay: 9;

/*
* Scrollbar.
*/
$scrollbar-size: 8px;

/*
* Footer.
*/
$footer-spaces: $space-base-double $space-base;
$footer-spaces-list: 0 0 $space-base-half;
$footer-spaces-socials: $space-base-half;
$footer-spaces-info-col: $space-base;
$footer-spaces-copyright: $space-base-half 0;

/*
* Section.
*/
$section-spaces: $space-base-cube $space-base;
$section-spaces-big: $space-base-biggest $space-base;

$section-secondary-info-width: 680px;
$section-secondary-spaces: $space-base-cube $space-base;
$section-secondary-spaces-big: 40px $space-base-double;

/*
* Page Title.
*/
$title-font-size: $font-size-lg;
$title-font-size-mob: $font-size-md;
$title-spaces: 0 0 $space-base-double;
$title-spaces-big: 0 0 $space-base-triple;

/*
* Border Radius.
*/
$border-radius-base: 2px;

/*
* Animation.
*/
$transition-base: 270ms ease-in-out;

/*
* Contact.
*/
$contact-col-space: $space-base-double;

/*
* Controls (use for input || selector || buttons).
*/
$control-height: 48px;
$control-spaces: $space-base-small $space-base;

/*
* Buttons.
*/
$btn-height: $control-height;
$btn-spaces: $control-spaces;
$btn-icon-space: $space-base-small;
$btn-font-size: $body-font-size;
$btn-font-weight: 400;
$btn-width-min: 160px;

/*
* Form.
*/
$form-row-spaces: $space-base;
$form-actions-spaces: $form-row-spaces;

/*
* Inputs.
*/
$input-height: $control-height;
$input-spaces: $control-spaces;
$input-font-size: $body-font-size;

/*
* Textarea.
*/
$textarea-height: $input-height*2;
$textarea-height-max: 240px;
$textarea-spaces: $space-base;

/*
* Cards.
*/
$card-preview-img-size: 240px;
$card-spaces: $space-base-double;
$card-spaces-title: 0 0 $space-base;
$card-font-size-title: $font-size-md;
$card-font-size-title-mob: $font-size-sm;

/*
* Grid.
*/
$grid-width: 30%;
$grid-spaces: $space-base-double;
$grid-font-size: $font-size-small;
$grid-ico-size: 40px;
$grid-ico-circle-size: 90px;
$grid-title: $space-base;

/*
* Svg.
*/
$svg-size: 24px;
$svg-size-md: 28px;

/*
* List.
*/
$list-spaces: $space-base-small 0 0;
$list-circle-size: 4px;
$list-circle-spaces: $space-base-half;

/*
* Breadcrumbs.
*/
$breadcrumbs-font-size: $font-size-base;
$breadcrumbs-spaces: $space-base-half;
$breadcrumbs-spaces-mob: $space-base-small;
$breadcrumbs-circle-size: 4px;

/*
* Icons.
*/
$share-inline-ico-spaces: $space-base-half;

/*
* Radio Button.
*/
$switcher-height: $control-height;
$switcher-spaces: 0 $space-base;
$switcher-btn-width: 120px;

/*
* Badge.
*/
$badge-height: 24px;
$badge-spaces: 0 $badge-height/2;
$badge-font-size: $font-size-small;

/*
* Date.
*/
$date-spaces: $space-base-small;
$date-size: $font-size-small;

