@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

.cf-switcher {
  margin-bottom: $space-base;

  // For Desktop & Tablet.
  @media (min-width: $screen-sxx) {
    & {
      @include flex-variant(inline-flex, flex-start, center);
      flex-wrap: wrap;
    }
  }

  &__btn {
    position: relative;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    @include flex-variant(flex, center, center);
    min-width: $switcher-btn-width;
  }


  &__label {
    flex: 1;
    @include flex-variant(flex, center, center);
    height: $switcher-height;
    padding: $switcher-spaces;
    text-transform: capitalize;

    border: 1px solid var(--switcher-label-border-color);
    z-index: 1;

    // If the device has :hover.
    @media (hover: hover) {
      & {
        transition: color $transition-base, border-bottom-color $transition-base;

        &:hover {
          color: var(--switcher-labe-actionl-color);
          border-bottom-color: var(--switcher-label-border-action-color);

          &:before {
            transform: translateY(calc(100% - 4px));
          }
        }
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--switcher-label-action-bg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(100%);
      z-index: -1;

      // If the device has :hover.
      @media (hover: hover) {
        & {
          transition: transform $transition-base;
        }
      }
    }
  }

  &__input {
    display: none;

    // If the button is selected.
    &:checked + .cf-switcher__label {
      color: var(--switcher-label-hover-color);

      &:before {
        transform: translateY(0);
      }
    }
  }
}
