@import "../mixins/mixin-container";
@import "../variables/variables";

.cf-section {
  &__container {
    position: relative;
    @include container($section-spaces);

    // For Desktop & Tablet.
    @media (min-width: $screen-md) {
      padding: $section-spaces-big;
    }
  }

  &--gray {
    background-color: var(--section-gray);
  }
}

[class*="cf-section"] {
  overflow: hidden;
}

// one-line to reverse the layout of any section
.reverse{
  flex-direction: row-reverse;
}
