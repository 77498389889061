@import "../../variables/variables";
@import "mixin-card-style";

.cf-card-collapse {
  @include card-style;
  width: 100%;

  p:last-child {
    margin: 0;
  }

  &__head {
    display: flex;
    align-items: center;
    line-height: 1.2;
    font-size: 18px;
    padding: $space-base;
    cursor: pointer;

    // For Desktop (Large Screen).
    @media (min-width: $screen-sm) {
      padding: $space-base-double;
    }

    &:hover {
      color: var(--color-primary);
    }
  }

  &__body {
    padding: 0 $space-base $space-base;

    // For Desktop (Large Screen).
    @media (min-width: $screen-sm) {
      padding: 0 $space-base-double $space-base-double;
    }
  }

  &__title {
    flex: 1;
  }

  &__ico {
    height: $svg-size-md;
    width: $svg-size-md;
    transition: transform $transition-base;
    margin-left: $space-base;
  }

  &.is-open {
    .cf-card-collapse__ico {
      transform: rotate(-180deg);
    }
  }
}
