@import "variables/variables";

::-webkit-scrollbar {
  width: $scrollbar-size;
  height: $scrollbar-size;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-bg);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-moz-selection {
  background: var(--selection-bg);
  color: var(--selection-color);
}

::selection {
  background: var(--selection-bg);
  color: var(--selection-color);
}

body {
  color: var(--body-color);
  background: var(--color-white);
  font-family: $body-font-family;
  line-height: $body-line-height;
  font-size: $body-font-size;
  display: flex;
  overflow: hidden;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cf-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  &__content {
    flex: 1;
  }
}

.cf-padding-t-base {
  padding-top: $space-base !important;
}

p {
  margin: 0 0 $space-base 0;
}

svg {
  height: $svg-size;
  width: $svg-size;
  color: currentColor;
}
