@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

.cf-nav {
  background-color: var(--nav-bg);
  z-index: $z-index-nav;
  border-color: var(--nav-border);
  border-style: solid;
  border-width: 0 0 1px;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }

  &__btn {
    @include flex-variant(flex, center, center);
    background-color: var(--nav-btn-bg);
    color: var(--nav-btn-color);
    letter-spacing: 1.4px;
    padding: $nav-btn-spaces;
    min-width: $nav-btn-width;
    height: $nav-height;
    cursor: pointer;
    margin-left: $space-base;

    &:focus,
    &:hover {
      background-color: var(--nav-btn-bg--action);
      color: var(--nav-btn-color--action);
    }
  }

  &__link,
  &__dropdown {
    @include flex-variant(flex, flex-start, center);
    user-select: none;
    height: 100%;
    padding: $nav-link-spaces-desktop;
    justify-content: center;
  }

  &__dropdown > span,
  &__link {

    &.is-active,
    &:focus,
    &:hover {
      color: var(--nav-menu-item-color--actions);
    }
  }

  &__dropdown {
    cursor: pointer;
    position: relative;

    &-ico {
      width: $nav-dropdown-ico-size;
      height: $nav-dropdown-ico-size;
      transition: $transition-base;
    }

    &.is-open {
      .cf-nav__dropdown-ico {
        transform: rotate(-180deg);
      }
    }

    &__menu {
      background-color: var(--nav-bg);
      border-color: var(--nav-border);
      border-style: solid;
      border-width: 0 1px 1px;
      position: absolute;
      top: 100%;
      right: 0;
      max-width: $nav-dropdown-max-width;
      min-width: $nav-dropdown-min-width;
    }

    &__link {
      height: $nav-dropdown-link-height;
      padding: $nav-dropdown-link-spaces;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &.is-active,
      &:focus,
      &:hover {
        background-color: var(--nav-border);
        color: var(--nav-dropdown-item-color--actions);
      }
    }
  }

  &__logo {
    font-size: $nav-logo-font-size;
    line-height: 1.2;
    font-weight: 600;
    white-space: nowrap;
    text-transform: capitalize;
    color: var(--nav-font-size-color);
    display: flex;
    align-items: center;
    flex: 1;

    img {
      margin: $nav-logo-img-spaces;
      height: $nav-logo-img-size;
      width: $nav-logo-img-size;
    }

    span {
      display: none;
    }

    // For Desktop & Tablet.
    @media (min-width: $screen-sx) {
     span {
       display: block;
     }
    }
  }

  &__menu {
    @include flex-variant(flex, center, center);
  }
}

.cf-menu-desktop {
  display: none;

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    display: flex;
    height: $nav-height;
  }
}
