@import "../variables/variables";

.cf-list {
  padding: $list-spaces;

  &__item {
    display: flex;
    align-items: center;
    line-height: 1.4;

    &:not(:last-child) {
      padding-bottom: $space-base-half;
    }
  }

  &__circle {
    height: $list-circle-size;
    width: $list-circle-size;
    background-color: var(--list-circle-bg);
    display: block;
    margin-right: $list-circle-spaces;
    border-radius: 50%;
  }
}
