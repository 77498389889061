@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

#app_groceries {
    height: auto;
    width: 100%;
}

#left-arm {
    position: absolute;
    animation: point 2s infinite linear;
    transform-origin: right;
    transform-box: fill-box;
}

#hair {
    //animation: point 2s linear 0s infinite alternate;
}

@keyframes point {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
