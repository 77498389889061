@import "../mixins/mixin-response-font";
@import "../mixins/mixin-flex-variant";

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      font-size: $hero-font-size-mobile;
      height: 108vh;

      &__info {
        max-width: 100%;
      }
    }
  }
}

.image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      font-size: $hero-font-size-mobile;
      height: 100%;

      &__info {
        max-width: 100%;
      }
    }
  }
}

.left-arrow {
  position: absolute;
  top: 32%;
  left: 3px;
  font-size: 30rem;
  transform: scaleY(1.5);
  width: 100px;
  height: 100px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  color: var(--color-primary);
  transition: 0.2s ease-in-out;
  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      font-size: $hero-font-size-mobile;
      top: 55%;
      color: white;
      opacity: 0.8;
      &__info {
        max-width: 100%;
      }
    }
  }
}

.right-arrow {
  position: absolute;
  top: 32%;
  right: 3px;
  font-size: 30rem;
  transform: scaleY(1.5);
  width: 100px;
  height: 100px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  color: var(--color-primary);
  transition: 0.2s ease-in-out;
  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      color: white;
      font-size: $hero-font-size-mobile;
      top: 55%;
      opacity: 0.8;
      &__info {
        max-width: 100%;
      }
    }
  }
}

//background color for the text.
.text-container {
  background-color: rgba(238, 122, 69, 0.8); //still var(--color-primary), just added opacity
  width: 100%;
}

.text {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  font-family: Poppins;
  color: white;
  z-index: 1;
  -webkit-animation-name: posi;
  -webkit-animation-duration: 2s;
  animation-name: posi;
  animation-duration: 2s;
  z-index: 1;

  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      font-size: $hero-font-size-mobile;

      &__info {
        max-width: 100%;
      }
    }
  }
}

@-webkit-keyframes posi {
  from {
    left: 25%;
  }
  to {
    left: 15%;
  }
}
@keyframes posi {
  from {
    left: 25%;
  }
  to {
    left: 15%;
  }
}

.left-arrow:hover,
.right-arrow:hover {
  opacity: 1;
  transform: scale(2);
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1);
  height: 108vh;

  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    @include flex-variant(flex, space-between, unset);
    flex-wrap: wrap;
  }
}

.dotsbox {
  display: flex;
  //position: absolute;
  //left: 50%;
  //transform: translate(-50%);
  //padding-bottom: 20px;

  cursor: pointer;
  // For Desktop & Tablet.
  @media (min-width: $screen-md) {
    @include flex-variant(flex, space-between, unset);
    flex-wrap: wrap;
    margin-bottom: 0px;
  }

  @media (max-width: $screen-sxx) {
    //for mobile
    padding: 5px 0;
  }
}

.dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
}
.active,
.dot:hover {
  border-color: coral;
}

@-webkit-keyframes zoomin {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}
@keyframes zoomin {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

.cf-bg {
  transform: scale(1.5, 1.5);
  -webkit-animation-name: zoomin;
  -webkit-animation-duration: 40s;
  animation-name: zoomin;
  animation-duration: 40s;

  &--home-slide-1 {
    min-height: 100vh;
    width: 100%;
    background: url("https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1651020697/comidaforfamilias-site/home/slide_1_qrqrwu.png")
      center/cover fixed no-repeat;

    // For Mobile.
    @media (max-width: $screen-sxx) {
      & {
        font-size: $hero-font-size-mobile;
        min-height: 108vh;
      }
    }
  }

  &--home-slide-2 {
    min-height: 100vh;
    width: 100%;
    background: url("https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1651020698/comidaforfamilias-site/home/slide_2_i2hpkk.jpg")
      center/cover fixed no-repeat;
          // For Mobile.
    @media (max-width: $screen-sxx) {
      & {
        font-size: $hero-font-size-mobile;
        min-height: 108vh;
      }
    }
  }

  &--home-slide-3 {
    min-height: 100vh;
    width: 100%;
    background: url("https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1651023050/comidaforfamilias-site/home/slide_3_pviqom.jpg")
      center/cover fixed no-repeat;

          // For Mobile.
    @media (max-width: $screen-sxx) {
      & {
        font-size: $hero-font-size-mobile;
        min-height: 108vh;
      }
    }
  }
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
// @-webkit-keyframes fade {
//   form {
//     opacity: 0.8;
//   }
//   to {
//     opacity: 1;
//   }
// }
@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

/*
.cf-carousel {
    &_slide {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        z-index: 1;
        background-color: black;
        display: flex;
        align-items: center;
    }

  // For Mobile.
  @media (max-width: $screen-sxx) {
    & {
      font-size: $hero-font-size-mobile;

      &__info {
        max-width: 100%;
      }
    }
  }
}*/
