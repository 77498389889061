@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

.cf-team {

  // Grid of Images.
  &__grid {
    margin: -$space-base-half;

    &-col {
      width: 100%;
      padding: $space-base-half;
      display: flex;
      flex-direction: column;
    }

    // For Desktop & Tablet.
    @media (min-width: $screen-sxx) {
      & {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-col {
          flex-basis: 25%;
        }
      }
    }
  }

  // Card.
  &__card {
    background-color: var(--color-white);
    overflow: hidden;
    border: 1px solid var(--color-grey-light);
    flex: 1;

    &-img {
      min-width: 240px;
      min-height: 240px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &-title {
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 1.1;
    }

    &-body {
      text-align: center;
      padding: $space-base;
    }

    &-socials {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-primary);

      a:not(:last-child) {
        padding: $space-base-small;
      }
    }

    // For Desktop & Tablet - Animate Image.
    @media (min-width: $screen-sxx) {
      & {
        position: relative;
        color: var(--color-white);

        &:hover {
          .cf-team__card-body {
            transform: translateY(0);
          }
        }

        &-body {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          @include flex-variant(flex, center, center);
          flex-direction: column;
          background-color: var(--color-primary-rgba);
          transition: transform $transition-base;
          transform: translateY(-100%);
        }

        a {
          color: var(--color-white) !important;
        }
      }
    }
  }
}
