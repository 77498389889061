@import "../variables/variables";
@import "../mixins/mixin-flex-variant";

.cf-badge {
  background-color: var(--badge-default-bg);
  color: var(--badge-default-color);
  padding: $badge-spaces;
  height: $badge-height;
  font-size: $badge-font-size;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  text-transform: capitalize;
  @include flex-variant(inline-flex, center, center);

  &__primary {
    background-color: var(--badge-primary-bg);
    color: var(--badge-primary-color);
  }

  &__secondary {
    background-color: var(--badge-secondary-bg);
    color: var(--badge-secondary-color);
  }
}
