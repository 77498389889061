@mixin container($spaces) {
  padding: $spaces;

  // For Desktop & Tablet.
  @media (min-width: $screen-sm) {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}
