@import "../mixins/mixin-response-font";
@import "../mixins/mixin-flex-variant";
@import "../variables/variables-theme";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body {
    margin:0;
    padding:0;
    
}
.timeline {
    position: relative;
    margin: 50px auto;
    padding: 40px 0;
    box-sizing: border-box;
}
.timeline:before{
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background: #c5c5c5;
}
.timeline ul {
 margin: 0;
 padding: 0;
}
.timeline ul li {
    list-style: none;
    position: relative;
    width: 50%;
    padding: 40px 20px;
    box-sizing: border-box;

}
.timeline ul li:nth-child(odd) {
    float: left;
    text-align: right;
    clear: both;


}
.timeline ul li:nth-child(even) {
    float: right;
    text-align: left;
    clear: both;
    

}
.content {
    padding-bottom: 20px;
}
.timeline ul li:nth-child(odd):before {
    content: '';
    position: absolute;
    top: 24px;
    right: -6px;
    width: 10px;
    height: 10px;
    background: rgb(128, 103, 91)

    ;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(227,124,75,0.2);
}
.timeline ul li:nth-child(even):before {
    content: '';
    position: absolute;
    top: 24px;
    left: -4px;
    width: 10px;
    height: 10px;
    background: var(--color-primary);
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(227,124,75,0.2);
}
.timeline ul li h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    color:rgba(227,124,75,1);

}
.timeline ul li p {
    margin: 10px 0 0;
    padding: 0;

}
.timeline ul li .time h4{
    margin: 0;
    padding: 0;
    font-size: 14px;

}
.timeline ul li:nth-child(odd) .time {
    position: absolute;
    top: 12px;
    right: -165px;
    margin:0;
    padding: 8px 16px;
    background: rgba(227,124,75,1);
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 0 3px rgba(227,124,75,0.3);
}
.timeline ul li:nth-child(even) .time {
    position: absolute;
    top: 12px;
    left: -165px;
    margin:0;
    padding: 8px 16px;
    background: rgba(227,124,75,1);
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 0 3px rgba(227,124,75,0.3);
}