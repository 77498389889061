@import "../variables/variables";

.cf-opp {
  &__subtitle {
    font-size: $font-size-sm;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &:hover {
      color: var(--color-primary);
    }
  }

  &__ico {
    transition: transform $transition-base;
    margin-left: $space-base-small;
  }

  .is-open {
    .cf-opp__ico {
      transform: rotate(-180deg);
    }
  }
}
